import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Button, Link } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { gql } from '../graphql';
import { ReservationCard } from '../components/reservation-card';
import { Content } from '../components/content';
import { PageHeader } from '../components/page-header';
import { GenericLayout } from '../layouts/generic';
import { cachedQuery } from '../graphql/cached-get';
import type { ReservationFragment } from '../graphql';
import type { RouteDefinition } from '@solidjs/router';

export default function Reservations() {
	const data = createAsync(() => getReservations({}), { deferStream: true });
	return (
		<GenericLayout>
			<Content>
				<Title>Reservations | Troon</Title>
				<div class="mb-8 border-b border-neutral-300 pb-8 md:mb-12 md:pb-12">
					<PageHeader title="Reservations" subtext={<p>View and manage all of your tee times.</p>}>
						<Button appearance="primary" as={Link} href="/" class="md:shrink md:grow-0">
							Book a tee time
						</Button>
					</PageHeader>
				</div>

				<div class="mb-12">
					<h2 class="mb-4 text-2xl font-semibold">Upcoming reservations</h2>
					<ul class="flex flex-col gap-y-8">
						<Suspense>
							<Show when={data()}>
								<For
									each={data()?.upcoming}
									fallback={
										<li>
											<Button as={Link} href="/">
												Book tee times now
											</Button>
										</li>
									}
								>
									{(res) => (
										<li>
											<ReservationCard {...(res as ReservationFragment)} />
										</li>
									)}
								</For>
							</Show>
						</Suspense>
					</ul>
				</div>

				<div class="mb-12">
					<h2 class="mb-4 text-2xl font-semibold">Past reservations</h2>
					<ul class="flex flex-col gap-y-8">
						<Suspense>
							<Show when={data()}>
								<For each={data()?.past} fallback={<li>No previous reservations</li>}>
									{(res) => (
										<li>
											<ReservationCard {...(res as ReservationFragment)} />
										</li>
									)}
								</For>
							</Show>
						</Suspense>
					</ul>
				</div>
			</Content>
		</GenericLayout>
	);
}

export const route = {
	preload: () => getReservations({}),
} satisfies RouteDefinition;

const reservationsQuery = gql(`
query allReservations {
	upcoming: allReservations(upcoming: true, past: false) {
		...Reservation
	}
	past: allReservations(upcoming: false, past: true, sortOrder: DESC) {
		...Reservation
	}
}`);

const getReservations = cachedQuery(reservationsQuery);
